<template>
  <div id="classicFooter">

    <ul>
      <li>
        <a :href="$t('pgFooter.conditions')" @click.prevent="openPDFFile('conditions')" download tabindex="0">{{$t('pgFooter.conditions')}}</a>
      </li>
      <li v-if="$t('pgFooter.privacy.link').length > 0">
        <!-- Link must be specified in translations files to appears -->
        <a :href="$t('pgFooter.privacy.link')" target="_blank" rel="noopener" tabindex="0">{{$t('pgFooter.privacy.label')}}</a>
      </li>
      <li v-if="$t('pgFooter.otherconditions.link').length > 0">
        <!-- Link must be specified in translations files to appears -->
        <a :href="$t('pgFooter.otherconditions.link')" target="_blank" rel="noopener" tabindex="0">{{$t('pgFooter.otherconditions.label')}}</a>
      </li>

      <li v-if="$t('pgFooter.mycoords.link').length > 0">
        <!-- Link must be specified in translations files to appears -->
        <a :href="$t('pgFooter.mycoords.link')" target="_blank" rel="noopener" tabindex="0">{{$t('pgFooter.mycoords.label')}}</a>
      </li>
      <li v-if="$t('pgFooter.contact.link').length > 0">
        <!-- Link must be specified in translations files to appears -->
        <a :href="$t('pgFooter.contact.link')" target="_blank" rel="noopener" tabindex="0">{{$t('pgFooter.contact.label')}}</a>
      </li>
    </ul>

  </div>
</template>

<!-- ================================================================================== -->

<script>
import openPDF from "../mixins/openpdf";

export default {
  name:"ClassicFooter",
  mixins: [openPDF],
}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

</style>
