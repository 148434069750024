<template>
	<div class="langswitcher" :class="{inheader: inHeader, outheader: !inHeader}">

		<lang-button label="Belgique" tolocale="fr_BE" v-if="langswitchsThatMustBePresent.includes('FR_BE')" :isActive="$i18n.locale === 'fr_BE'"></lang-button>
		<lang-button label="België" tolocale="nl_BE" v-if="langswitchsThatMustBePresent.includes('NL_BE')" :isActive="$i18n.locale === 'nl_BE'"></lang-button>
		<lang-button label="Luxembourg" tolocale="fr_LU" v-if="langswitchsThatMustBePresent.includes('FR_LU')" :isActive="$i18n.locale === 'fr_LU'"></lang-button>
		<lang-button label="France" tolocale="fr_FR" v-if="langswitchsThatMustBePresent.includes('FR_FR')" :isActive="$i18n.locale === 'fr_FR'"></lang-button>
		<lang-button label="Nederland" tolocale="nl_NL" v-if="langswitchsThatMustBePresent.includes('NL_NL')" :isActive="$i18n.locale === 'nl_NL'"></lang-button>

	</div>
</template>

<!-- ================================================================================== -->

<script>

	import LangButton from '@/components/LangButton';

	export default {
		name:"langswitcher",
		
		props: {
			inHeader: {
				type: Boolean,
				required: false,
				default: true,
			}
		},

		components: {
			LangButton 
		},

		data(){
			return {
				langswitchsThatMustBePresent: this.$store.getters.getLangList,
			}
		},

		watch: {
			// To be sure it load at startup
			'$store.state.langList': function(/* newLocale, oldLocale */){    
				this.langswitchsThatMustBePresent = this.$store.getters.getLangList;
			},
		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
